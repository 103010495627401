<script>
import { FROM_HISTORY } from '@src/utils/consts'
// eslint-disable-next-line no-unused-vars
const $gettext = s => s // to force vue-i18n-extract to see translations

export default {
  name: 'Navigation',
  props: {
    currentStep: {
      type: String,
      default: () => '',
    },
    documentVersion: {
      type: Object,
      default: () => {},
    },
    indexFrom: {
      type: String,
      required: true,
    },
    lockedMode: {
      type: Boolean,
      default: () => false,
    },
    reducedView: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    'seeHistoricDocumentVersion',
    'changeStep',
    'goBackToPreviousPage',
  ],
  computed: {
    canGoToPreviousStepButton () {
      const firstStep = this.documentVersion.stepTransitions[0].currentStepTemplate.type
      return this.currentStep != firstStep
    },
    canGoToNextStepButton () {
      const lastStep = this.documentVersion.stepTransitions.slice(-1)[0].currentStepTemplate.type
      return this.currentStep != lastStep
    },
    otherVersions () {
      if (this.documentVersion) {
        return this.documentVersion.document.versions
          .filter(version => version.index !== this.documentVersion.index)
          .sort((v1, v2) => v1.index - v2.index)
      } else {
        return []
      }
    },
    isLatestVersion () {
      if (this.documentVersion) {
        const versions = this.documentVersion.document.versions
        const latestVersionIndex = versions.sort((v1, v2) => v1.index - v2.index)[versions.length - 1].index
        return this.documentVersion.index === latestVersionIndex
      } else {
        return false
      }
    },
    canISeeOtherIndexes () {
      return !!this.otherVersions.length
    },
    getBackButtonMessage () {
      if (!this.isLatestVersion) {
        return this.$gettext('Go back to the latest version')
      } else {
        if (this.indexFrom === FROM_HISTORY) {
          return this.$gettext('Go back to the archives')
        } else {
          return this.$gettext('Go back to the document list')
        }
      }
    },
  },
  methods: {
    seeHistoricDocumentVersion (versionDoc) {
      this.$emit('seeHistoricDocumentVersion', versionDoc)
    },
    changeStep (key) {
      this.$emit('changeStep', key)
    },
    handleGoBack () {
      this.$emit('goBackToPreviousPage')
    },
  },
}
</script>
<template>
  <div class="card document-navigation">
    <div
      class="go-back hover-left"
      @click="handleGoBack"
    >
      <v-icon
        color="secondary"
        icon="fas fa-arrow-left"
        start
      />
      {{ getBackButtonMessage }}
    </div>
    <div class="navigation-buttons">
      <v-menu
        v-if="canISeeOtherIndexes"
        class="document-version-navigation"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            variant="text"
            color="secondary"
            prepend-icon="fas fa-backward"
            v-bind="props"
          >
            {{ $gettext('See other indexes') }}
          </v-btn>
        </template>
        <v-list density="compact">
          <v-list-item
            v-for="versionDoc in otherVersions"
            :key="versionDoc.id"
            @click="seeHistoricDocumentVersion(versionDoc)"
          >
            <v-list-item-title>{{ $gettext('Index') }} {{ versionDoc.index }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <div
        v-if="lockedMode"
        class="document-version-step-navigation"
      >
        <v-btn
          color="secondary"
          size="small"
          variant="text"
          :disabled="!canGoToPreviousStepButton"
          icon="fas fa-arrow-left"
          @click="changeStep(-1)"
        />
        <v-btn
          color="secondary"
          size="small"
          variant="text"
          :disabled="!canGoToNextStepButton"
          icon="fas fa-arrow-right"
          @click="changeStep(+1)"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.document-navigation {
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
}
.go-back {
  color: $secondary;
}
.go-back:hover {
  cursor: pointer;
}
.document-version-navigation {
  max-height: 90vh;
}
.navigation-buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: baseline;
}
.document-version-step-navigation {
  flex-grow: 0;
  display: flex;
  gap: 1ch;
}
</style>
